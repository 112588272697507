import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const RedirectComponent: React.FC = () => (
  <div style={{ textAlign: 'center', alignItems: 'center' }}>
    <div className="redirect">
      <ClipLoader color="black" size={100} />

      <div
        style={{
          width: '30%',
          backgroundColor: '#ffb925',
          height: 5,
          margin: 'auto',
          marginTop: 30,
          marginBottom: 20,
        }}></div>
      <p
        style={{
          color: '#707070',
          fontSize: 18,
          padding: 10,
          textAlign: 'center',
          width: '50%',
          margin: 'auto',
        }}>
        Stiamo lavorando per consigliarti il prodotto più adatto a te!
      </p>
    </div>
  </div>
);

export default RedirectComponent;
