import React, { useEffect, useState } from 'react';
import RedirectComponent from '../../components/RedirectComponent';
import Step from './Steps';
import '../../App.css';

const STEPS = [
  [
    { text: 'La mia pelle è ruvida', id: 1 },
    { text: 'La mia pelle presenta rughe marcate', id: 2 },
    { text: 'La mia pelle presenta rughe leggermente marcate', id: 3 },
    { text: 'La mia pelle non ha un colorito omogeneo', id: 4 },
  ],
  [
    { text: 'La mia pelle si presenta con macchie o rossori diffusi', id: 5 },
    { text: 'La mia pelle si presenta con brufoli', id: 6 },
    { text: 'La mia pelle non presenta particolari problematiche', id: 7 },
    { text: 'La mia pelle è lucida su fronte, naso e mento', id: 8 },
  ],
  [
    { text: 'La mia pelle mostra irritazioni dopo aver lavato il viso', id: 9 },
    { text: 'Noto secchezza e una sensazione di pelle tesa', id: 10 },
    { text: 'La mia pelle arrossisce con uno sbalzo di temperatura', id: 11 },
    { text: 'La mia pelle è secca e spenta su zigomi e mento', id: 12 },
  ],
  [
    { text: 'La mia pelle si screpola facilmente', id: 13 },
    { text: 'I miei pori sono dilatati', id: 14 },
    { text: 'La mia pelle è molto sensibile', id: 15 },
    { text: 'La mia pelle presenta oleosità generale', id: 16 },
  ],
  [
    { text: 'Non pratico sport', id: 17 },
    { text: 'Pratico sport più di 2 volte a settimana', id: 18 },
    { text: 'Pratico sport al chiuso', id: 19 },
    { text: "Pratico sport all'aperto", id: 20 },
  ],
];
const LINKS = [
  {
    link: 'https://www.aoga.it/products/beauty-routing-anti-age-s-o-s',
    answers: [1, 2, 3, 5, 9, 10, 13, 17],
  },
  {
    link: 'https://www.aoga.it/products/beauty-routine-illuminante-e-idratante',
    answers: [1, 3, 4, 5, 10, 12, 13, 20],
  },
  {
    link: 'https://www.aoga.it/products/beauty-routine-illuminante-e-riequilibrante',
    answers: [4, 5, 6, 8, 13, 14, 15, 16, 18],
  },
  {
    link: 'https://www.aoga.it/products/beauty-routine-anti-age-e-idratante',
    answers: [1, 3, 7, 9, 10, 12, 13],
  },
  {
    link: 'https://www.aoga.it/products/beauty-routine-anti-age-illuminante-e-riequilibrante',
    answers: [1, 3, 5, 6, 8, 13, 15, 16, 18, 19],
  },
  {
    link: 'https://www.aoga.it/products/beauty-routin-super-anti-age',
    answers: [1, 2, 3, 5, 9, 10, 13, 17],
  },
];
/* eslint-disable */
const getLink = (selectedAnswers: number[]) => {
  const [{ link }] = LINKS.map(({ answers, link }) => ({
    link,
    answers: answers.filter((item) => selectedAnswers.includes(item)),
  })).sort((a, b) => (a.answers.length < b.answers.length ? 1 : -1));
  return link;
};
/* eslint-enable */
const SurveyPresentational: React.FC = () => {
  const [showWarningText, setShowWarningText] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  /* eslint-disable */
  const onEachStep = () => {
    if (!STEPS[currentStep].map(({ id }) => id).some((id) => selectedAnswers.includes(id))) {
      return setShowWarningText(true);
    }
    setShowWarningText(false);
    currentStep < STEPS.length - 1 ? setCurrentStep(currentStep + 1) : setRedirectLink(getLink(selectedAnswers));
  };
  /* eslint-enable */

  const handleSelectedAnswer = (answerId: number) => {
    setSelectedAnswers((previouslySelectedAnswers) =>
      previouslySelectedAnswers.includes(answerId)
        ? previouslySelectedAnswers.filter((item) => item !== answerId)
        : [...previouslySelectedAnswers, answerId],
    );
    setShowWarningText(false);
  };
  /* eslint-disable */
  useEffect(() => {
    if (!redirectLink) return;
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      window.location.replace(redirectLink);
    })();
  }, [redirectLink]);
  /* eslint-enable */

  if (redirectLink) {
    return <RedirectComponent />;
  }
  return (
    <div className="App">
      <div style={{ margin: 'auto' }}>
        {STEPS.map((answers, stepIndex) => (
          <Step
            answers={answers}
            step={stepIndex}
            handleSelectedAnswer={handleSelectedAnswer}
            selectedAnswers={selectedAnswers}
            currentStep={currentStep}
            showWarningText={showWarningText}
          />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: currentStep !== 0 ? 'space-between' : 'flex-end',
          width: 'auto',
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 20,
        }}>
        {currentStep !== 0 && (
          <button
            style={{
              width: '49%',
              height: 85,
              textAlign: 'center',
              color: 'white',
              fontSize: 17,
              cursor: currentStep === 0 ? 'default' : 'pointer',
              fontWeight: 'bold',
              backgroundColor: '#ffb925',
              border: 'none',
            }}
            type="button"
            onClick={() => setCurrentStep(currentStep - 1)}>
            INDIETRO
          </button>
        )}
        <button
          style={{
            width: '49%',
            height: 85,
            textAlign: 'center',
            color: 'white',
            fontSize: 17,
            cursor: 'pointer',
            fontWeight: 'bold',
            backgroundColor: '#ffb925',
            border: 'none',
          }}
          type="button"
          onClick={() => onEachStep()}>
          {currentStep < STEPS.length - 1 ? 'AVANTI' : 'CONCLUDI'}
        </button>
      </div>
    </div>
  );
};

export default SurveyPresentational;
