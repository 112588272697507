import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HashRouter, useLocation, useNavigate, Location } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(_location: Location) {
        navigate(_location, { replace: true, state: location.state });
      },
      push(_location: Location) {
        navigate(_location, { replace: false, state: location.state });
      },
    }),
    /* eslint-disable */
    [navigate],
    /* eslint-enable */
  );
  return children({ history: adaptedHistory, location });
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HashRouter>
      <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
        <App />
      </QueryParamProvider>
    </HashRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
