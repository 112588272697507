import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Survey from './pages/Survey';

function App() {
  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path="/survey" element={<Survey />} />
    </Routes>
  );
}

export default App;
