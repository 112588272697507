import React from 'react';

interface IAnswer {
  answer: string;
  isSelected: boolean;
  onClick: () => void;
}
const Answer: React.FC<IAnswer> = ({ answer, isSelected = false, onClick }) => (
  /* eslint-disable */
  <div
    style={{
      backgroundColor: '#f5f5f5',
      width: 'auto',
      marginRight: 15,
      marginLeft: 15,
      height: 'auto',
      padding: 20,
      marginBottom: 20,
      border: isSelected ? ' 2px solid #FFB925' : '0px',
    }}
    onClick={onClick}>
    <p style={{ color: '#707070', width: 'auto', textAlign: 'center' }}>{answer}</p>
  </div>
  /* eslint-enable */
);

export default Answer;
