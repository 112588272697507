import React from 'react';
import SurveyPresentational from './SurveyPresentational';

const Survey: React.FC = () => (
  <div>
    <SurveyPresentational />
  </div>
);

export default Survey;
