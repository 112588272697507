import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="App" style={{}}>
      <header>
        <img src="./images/Aoga-logo.png" alt="logo" style={{ padding: 40, marginTop: 30 }} />
      </header>
      <img src="./images/Aoga-bg-image.webp" alt="bg" style={{ maxWidth: '100%' }} />
      <div
        style={{
          width: '30%',
          backgroundColor: '#ffb925',
          height: 5,
          margin: 'auto',
          marginTop: 30,
          marginBottom: 30,
        }}></div>{' '}
      <h1 style={{ color: '#707070', fontSize: 24 }}>Prenditi cura della tua pelle</h1>
      <p style={{ color: '#707070', fontSize: 18, paddingLeft: 50, paddingRight: 50 }}>
        Presta attenzione a ciò che accade alla tua cute ogni giorno e trova il prodotto più adatto a te
      </p>
      <button
        style={{
          width: 175,
          height: 50,
          marginTop: 30,
          backgroundColor: '#ffb925',
          textTransform: 'uppercase',
          color: 'white',
          border: 'none',
          fontWeight: 'bold',
          fontSize: 17,
          cursor: 'pointer',
        }}
        type="button"
        onClick={() => navigate('Survey')}>
        Inizia subito
      </button>
      <footer style={{ textAlign: 'center', marginTop: 100, marginBottom: 'auto' }}>
        <p style={{ color: '#707070', fontSize: 9 }}>V. 0.1</p>
      </footer>
    </div>
  );
};

export default LandingPage;
