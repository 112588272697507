import React from 'react';
import Answer from '../../../components/Answer';

interface IStep {
  step: number;
  answers: { text: string; id: number }[];
  /* eslint-disable */
  handleSelectedAnswer: (selectedAnswerIndex: number) => void;
  /* eslint-enable */
  selectedAnswers: number[];
  currentStep: number;
  showWarningText: boolean;
}

const Step: React.FC<IStep> = ({
  step,
  answers,
  handleSelectedAnswer,
  selectedAnswers,
  currentStep,
  showWarningText,
}) => {
  if (step !== currentStep) {
    return null;
  }
  return (
    <div
      style={{
        textAlign: 'center',
        alignItems: 'center',
        width: 'auto',
        height: 'auto',
      }}>
      <h2 style={{ color: '#707070', fontSize: 24, textAlign: 'center', alignItems: 'center' }}>{step + 1} di 5</h2>
      <div
        style={{
          width: '30%',
          backgroundColor: '#ffb925',
          height: 5,
          margin: 'auto',
          marginTop: 30,
          marginBottom: 30,
        }}></div>
      <p style={{ color: '#707070', fontSize: 18, paddingLeft: 50, paddingRight: 50, textAlign: 'center' }}>
        Seleziona le caratteristiche che ti rappresentano:
      </p>
      <div style={{ margin: 'auto' }}>
        {answers.map((answer) => (
          <Answer
            answer={answer.text}
            isSelected={selectedAnswers.includes(answer.id)}
            onClick={() => handleSelectedAnswer(answer.id)}
            key={answer.id}
          />
        ))}
      </div>
      {showWarningText && (
        <p style={{ fontSize: 15, color: '#FFB925' }}>Seleziona almeno una opzione per poter proseguire</p>
      )}
    </div>
  );
};
export default Step;
